import { history, notify } from "./index";

/* Handle all http resopnse from all API */
export const handleResponse = (response) => {
  if (response !== undefined) {
    return response.text().then((text) => {
      setLoading(false);
      const data = text && JSON.parse(text);
      if (
        data.type !== undefined &&
        data.type === "unauthorized" &&
        data.status === false
      ) {
        const error = (data && data.message) || response.statusText;
        notify.error(error);
        localStorage.removeItem("user");
        history.push("/admin/login");
        return Promise.reject(error);
      } else if (
        data.type !== undefined &&
        data.type === "access_denied" &&
        data.status === false
      ) {
        const error = (data && data.message) || response.statusText;
        notify.error(error);
        // history.push('/admin/dashboard');
        history.push({
          pathname: "/admin/dashboard",
          state: { access_message: true },
        });
        return Promise.reject(error);
      }
      return data;
    });
  } else {
    return Promise.reject("Something went wrong");
  }
};

/* Add/Remove loader */
export const setLoading = (flag) => {
  let loader = document.querySelector(".loader-container");
  if (flag) {
    loader.classList.add("loading");
  } else {
    loader.classList.remove("loading");
  }
};

/* Capitalized first character from string */
export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  var separateWord = s.toLowerCase().split(" ");
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] =
      separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
  }
  return separateWord.join(" ");
};

export const formatType = (type) => {
  // Split the string by underscores and capitalize each word
  const words = type
    ?.split("_")
    ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1));

  // Join the words back together
  const formattedType = words?.join(" ");

  return formattedType;
};

export const transformBookingSubStatus = (subStatus) => {
  switch (subStatus) {
    case "acceptby_guide":
      return "Accept By Guide";
    case "rejectby_guide":
      return "Reject By Guide";
    case "cancelby_customer":
      return "Cancel By Customer";
    case "cancelby_guide":
      return "Cancel By Guide";
    default:
      // If not one of the specified cases, apply the original logic
      const words = subStatus
        ?.split("_")
        ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1));
      return words?.join(" ");
  }
};

export const getCellStyle = (bookingStatus) => {
  let backgroundColor, color;
  switch (bookingStatus) {
    case "blocked":
      backgroundColor = "red";
      color = "white";
      break;
    case "reserved":
      backgroundColor = "#B2FFFF";
      break;
    case "pending_confirmation":
      backgroundColor = "orange";
      color = "black";
      break;
    case "acceptby_guide":
      backgroundColor = "#29AB87";
      color = "black";
      break;
    case "rejectby_guide":
      backgroundColor = "gray";
      color = "white";
      break;
    case "cancelby_customer":
      backgroundColor = "purple";
      color = "white";
      break;
    case "pending_cancellation":
      backgroundColor = "yellow";
      color = "black";
      break;
    case "cancelby_guide":
      backgroundColor = "brown";
      color = "white";
      break;
    case "failed":
      backgroundColor = "red";
      color = "black";
      break;
    default:
      backgroundColor = "white";
  }
  // Apply additional styles
  return {
    backgroundColor,
    // padding: "3px 10px",
    padding: "2px 7px",
    borderRadius: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    fontSize: "12px",
    color,
  };
};

/* Checkiang for menu permission */
export const menuPermission = (navigation) => {
  let user = JSON.parse(localStorage.getItem("user"));
  let permission_nav = [];
  if (user.user_group_id === "60227751e2e5152364d34551") {
    return navigation;
  } else if (user.user_group === "Super Users") {
    for (var key in navigation) {
      if (navigation[key].module_name !== "system_modules") {
        permission_nav.push(navigation[key]);
      }
    }
    return permission_nav;
  } else {
    for (var i in navigation) {
      if (navigation[i].module_name !== "system_modules") {
        if (
          user.user_permission[navigation[i].module_name] !== undefined ||
          navigation[i].module_name === "dashboard" ||
          navigation[i].module_name === undefined
        ) {
          permission_nav.push(navigation[i]);
        }
      }
    }
    return permission_nav;
  }
};

/* ACL page Access middleware */
export const _canAccess = (module, access = "", redirect = "") => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user.user_group_id === "60227751e2e5152364d34551") {
    return true;
  } else if (user.user_group === "Super Users" && module !== "system_modules") {
    return true;
  } else {
    if (
      user.user_permission[module] !== undefined &&
      user.user_permission[module].includes(access)
    ) {
      return true;
    } else if (redirect !== "") {
      notify.error("Access Denied Contact to Super User");
      history.push(redirect);
    } else if (redirect === "") {
      return false;
    } else {
      notify.error("Access Denied Contact to Super User");
      history.push("/admin");
    }
  }
};

/* Current user details send */
export const _loginUsersDetails = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const formatDate = (dateStr, onlyDate = false) => {
  // Convert to ISO string format
  const isoDateStr = dateStr?.replace(" ", "T");

  const dateObj = new Date(isoDateStr);
  if (isNaN(dateObj.getTime())) {
    return "Invalid Date";
  }

  const dd = String(dateObj.getDate()).padStart(2, "0");
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[dateObj.getMonth()];
  const yyyy = dateObj.getFullYear();

  let hh = dateObj.getHours();
  let period = "AM";
  if (hh >= 12) {
    if (hh > 12) hh -= 12; // Convert to 12-hour format
    period = "PM";
  }
  hh = String(hh).padStart(2, "0");
  const min = String(dateObj.getMinutes()).padStart(2, "0");

  if (onlyDate) {
    return `${dd}-${month}-${yyyy}`;
  } else {
    return `${dd}-${month}-${yyyy} at ${hh}:${min} ${period}`;
  }
};
