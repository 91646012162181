import * as React from "react";
const IconBooking = (props) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 60 60"
    style={{
      enableBackground: "new 0 0 60 60",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path d="M34.4,26.3c0.041,0.031,0.088,0.045,0.131,0.069c0.035,0.019,0.067,0.039,0.103,0.054c0.118,0.047,0.241,0.078,0.365,0.078 c0,0,0,0,0.001,0l0.001,0c0.136,0,0.268-0.036,0.396-0.092c0.039-0.017,0.072-0.042,0.109-0.064c0.05-0.03,0.104-0.049,0.149-0.088 L44,19.023V24.5c0,0.552,0.448,1,1,1s1-0.448,1-1v-7.21l4.655-4.034c0.417-0.362,0.462-0.994,0.101-1.411 c-0.362-0.417-0.993-0.463-1.411-0.101l-4.336,3.758c-0.003,0-0.006-0.002-0.009-0.002h-9c-0.552,0-1,0.448-1,1s0.448,1,1,1h6.704 l-7.749,6.716L31.6,21.7c-0.443-0.332-1.069-0.241-1.4,0.2c-0.332,0.442-0.242,1.069,0.2,1.4L34.4,26.3z" />
      <path d="M9,18.5h1.09c0.154,0.909,0.51,1.749,1.025,2.471l-0.772,0.772c-0.391,0.391-0.391,1.023,0,1.414 c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l0.772-0.772C13.251,22.9,14.091,23.256,15,23.41v1.09 c0,0.552,0.448,1,1,1s1-0.448,1-1v-1.09c0.909-0.154,1.749-0.51,2.471-1.025l0.772,0.772c0.195,0.195,0.451,0.293,0.707,0.293 s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414l-0.772-0.772c0.515-0.722,0.871-1.562,1.025-2.471H23 c0.552,0,1-0.448,1-1s-0.448-1-1-1h-1.09c-0.154-0.909-0.51-1.749-1.025-2.471l0.772-0.772c0.391-0.391,0.391-1.023,0-1.414 s-1.023-0.391-1.414,0l-0.772,0.772C18.749,12.1,17.909,11.744,17,11.59V10.5c0-0.552-0.448-1-1-1s-1,0.448-1,1v1.09 c-0.909,0.154-1.749,0.51-2.471,1.025l-0.772-0.772c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l0.772,0.772 c-0.515,0.722-0.871,1.562-1.025,2.471H9c-0.552,0-1,0.448-1,1S8.448,18.5,9,18.5z" />
      <path d="M20,27.5c0-0.552-0.448-1-1-1h-9c-0.552,0-1,0.448-1,1s0.448,1,1,1h9C19.552,28.5,20,28.052,20,27.5z" />
      <path d="M22,29.5h-8c-0.552,0-1,0.448-1,1s0.448,1,1,1h8c0.552,0,1-0.448,1-1S22.552,29.5,22,29.5z" />
      <path d="M49,28.5h-2c-0.552,0-1,0.448-1,1s0.448,1,1,1h2c0.552,0,1-0.448,1-1S49.552,28.5,49,28.5z" />
      <path d="M31,30.5h3c0.552,0,1-0.448,1-1s-0.448-1-1-1h-3c-0.552,0-1,0.448-1,1S30.448,30.5,31,30.5z" />
      <path d="M42,28.5h-3c-0.552,0-1,0.448-1,1s0.448,1,1,1h3c0.552,0,1-0.448,1-1S42.552,28.5,42,28.5z" />
      <path d="M0,43.001c0,2.48,2.018,4.499,4.499,4.499h17.855c0.142,1.752-0.618,3.481-2.023,4.472l-1.799,1.269 c-0.115,0.08-0.212,0.177-0.291,0.285C16.985,53.658,16,54.71,16,56c0,1.378,1.122,2.5,2.5,2.5h23c1.378,0,2.5-1.122,2.5-2.5 c0-1.289-0.985-2.341-2.239-2.474c-0.078-0.106-0.173-0.202-0.286-0.281l-1.804-1.271c-1.408-0.992-2.169-2.724-2.025-4.474h17.855 c2.48,0,4.499-2.018,4.499-4.499V38.5H0V43.001z M35.642,47.5c-0.134,2.33,0.882,4.619,2.728,6H21.63 c1.847-1.381,2.863-3.67,2.729-6H35.642z" />
      <path d="M55.501,1.5H4.499C2.018,1.5,0,3.518,0,5.999V36.5h60V5.999C60,3.518,57.982,1.5,55.501,1.5z M25,34.5H5v-28h20V34.5z  M55,34.5H27v-28h28V34.5z" />
    </g>
  </svg>
);
export default IconBooking;
