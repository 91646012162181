import * as React from "react";
const Tourist = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 452.113 452.113"
    style={{
      enableBackground: "new 0 0 452.113 452.113",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M412.652 263.782h-15.849v-36.833a9.611 9.611 0 0 0-9.612-9.612h-48.815c-5.309 0-9.612 4.304-9.612 9.612v36.833h-15.848c-7.965 0-14.421 6.456-14.421 14.421v136.04c0 7.965 6.456 14.421 14.421 14.421h.489v11.966c0 6.342 5.141 11.484 11.484 11.484 6.342 0 11.484-5.141 11.484-11.484v-11.966h52.824v11.966c0 6.342 5.141 11.484 11.484 11.484 6.342 0 11.483-5.141 11.483-11.484v-11.966h.489c7.964 0 14.421-6.456 14.421-14.421v-136.04c-.002-7.965-6.458-14.421-14.422-14.421zm-35.074 0h-29.59v-27.22h29.59v27.22zm-110.11 82.438c-2.353 3.803-.306 1.282-38.475 43.345l10.389 44.272c2.987 12.732 15.729 20.631 28.464 17.644 12.731-2.988 20.631-15.731 17.643-28.463l-18.021-76.798z" />
    <circle cx={123.721} cy={43.556} r={43.555} />
    <path d="m235.866 306.794-92.489-38.092v-.005l99.656 20.69a37.694 37.694 0 0 1 12.244 4.881l-5.273-22.469a23.68 23.68 0 0 0-23.054-18.27h-38.562l-.701-10.564-13.074.815c-11.389.71-21.531-6.069-25.656-16.058l-29.33-71.34 45.054 59.417a19.73 19.73 0 0 0 16.951 7.772l78.687-4.904c10.877-.678 19.145-10.045 18.468-20.922-.679-10.876-10.032-19.16-20.923-18.467l-68.127 4.246-38.661-50.986 30.887 24.136-1.729-26.055c-1.076-16.214-15.092-28.486-31.306-27.41l-52.354 3.473c-16.214 1.076-28.486 15.092-27.41 31.306 0 0 9.004 140.904 9.276 142.72.006.041.01.083.017.125.022.141.051.28.075.421 1.326 7.601 6.222 14.525 14.725 18.026H55.04V149.86c0-8.284-6.716-15-15-15s-15 6.716-15 15v164.419c0 8.284 6.716 15 15 15h126.07v.005l20.544 8.461-67.853 74.775c-8.788 9.685-8.062 24.66 1.623 33.449 9.694 8.796 24.668 8.054 33.449-1.624l90.51-99.743c11.027-12.151 6.624-31.572-8.517-37.808z" />
  </svg>
);
export default Tourist;