import * as React from "react";
const GuideReferral = (props) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 9a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v1h5v19H3V10h5V9Zm-3 3h3v2H5v-2Zm15 0v2h3v-2h-3ZM8 17v-1H5v11h18V16h-3v1a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2Zm5-8v3h-3v2h3v3h2v-3h3v-2h-3V9h-2Zm27 14c0 2.762-2.237 5-5 5s-5-2.238-5-5 2.237-5 5-5 5 2.238 5 5ZM25 35.333C25 31.787 31.663 30 35 30c3.337 0 10 1.787 10 5.333V41H13v-7.586l-3.293 3.293-1.414-1.414 5-5 .707-.707.707.707 5 5-1.414 1.414L15 33.414V39h10v-3.667Z"
      fill="#a4abb6"
    />
    <path d="M11 21h6v7h-6v-7Z" fill="#a4abb6" />
  </svg>
);
export default GuideReferral;